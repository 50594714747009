/*
 * https://google.github.io/material-design-icons/
 */

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// Pozor, icony nebudu sediet s text, bude treba zabezpecit vertical align
.material-icons {
  vertical-align: bottom !important;
  padding-top: 0px;
  //margin-right: 3px;
  font-size: 22px;
}
/*
.btn, a {
  display: inline-flex;
  horiz-align: center;
  vertical-align: bottom;
}
*/
/**
 * V pripade selfhostingu treba este pridat tieto pravidla
 * Predpoklada sa, ze fony mame na adresach https://example.com/MaterialIcons-Regular.*
 */
/*
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot);
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
  url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
  url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;


  -webkit-font-smoothing: antialiased;

  text-rendering: optimizeLegibility;

  -moz-osx-font-smoothing: grayscale;

  font-feature-settings: 'liga';
}
*/