h1, h2, h3, h4, h5, h6 {
  color: $theme-color-dark;
}

#sidebar {
  h1, h2, h3, h4, h5, h6 {
    color: $white;
  }
}


.alert {
  h1, h2, h3, h4, h5, h6 {
    color: $white;
  }
}