// farba textu navigatnych itemov
.navbar {
  padding: 0;
}

.navbar .nav-link {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:active,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:visited {
  color: $theme-color-dark;
}

.navbar-light .navbar-nav .nav-link:hover {
  background-color: #efefef;
}