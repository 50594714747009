/* Tabs*/

.nav-tabs .nav-link:hover {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom: 4px rgba($theme-color-secondary, .25) solid !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link.active:hover {
  color: $theme-color-info;
  background-color: transparent;
  border-color: transparent transparent $theme-color-info;
  border-bottom: 4px solid !important;
  transition-duration: 0s;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  //border-bottom: 4px $white solid !important;
  color: $theme-color-secondary;
  transition-duration: 0s;
}

