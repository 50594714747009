@import "initTheme";
@import "../theme/downloaded/jstree/dist/themes/default/style.min.css";
//@import "~prismjs";

// JSTree
.vakata-context {
  background-color: #fff;
  box-shadow: $btn-box-shadow;
}

.vakata-context-hover {
  border: none !important;
  border-radius: 0 !important;
  background-color: lighten($theme-color-secondary, 50) !important;
}

.note-toolbar {
  background-color: #fff !important;
}

.caption-text-color {
  color: #8a8a8a !important;
}

img {
  max-width: 100%;
  height: auto;
}

.text-read-more {
  overflow: hidden;
}

.height-read-more {
  max-height: 70px;
  margin-bottom: -45px;
  -webkit-mask-image: -webkit-gradient(linear, left top,
          left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
}

.height-read-more-auto {
  max-height: 5000px;
  -webkit-mask-image: -webkit-gradient(linear, left top,
          left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 1)));
}

.read-btn {
  margin-top: 33px;
  border-color: #dfdfdf;
  box-shadow: none;
  -webkit-mask-image: -webkit-gradient(linear, left top,
          left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 1)));
}

.btn-light {
  box-shadow: none;
  border: 1px solid #8a8a8a;
}

.w-10 {
  width: 10% !important;
}

.to, .bcc {
  padding-left: 5px !important;
}

.btn:focus {
  box-shadow: none;
}

.paragraph {
  //font-size: 18px;
  font-family: geomanist, Helvetica, Arial, serif !important;
}

.heading {
  //font-family: fell, Georgia, Cambria, "Times New Roman", Times, serif;
}

@media screen and (min-width: 970px) {
  .w-md-50 {
    width: 50% !important;
  }
}

.card-filter:hover {
  background-color: #eee;
}

.ck-content {
  min-height: 500px !important;
}

.footer{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
.showTextArea{
  display: inline-block !important;
}

.homePageIcon{
  font-size: 48px !important;
}

.frontUmbLogo{
  width: 120.5px !important;
}

.homePageHover{
  background-color: #ffffff;
}

.homePageHover:hover{
  background-color: #f7f7f7;
}
