// Adjust column spacing for symmetry
$spacer: 1rem;
$grid-gutter-width: $spacer * 1.5;

// Spacing variables for navigation
$topnav-base-height: 56px;
$sidenav-base-width: 225px;

// Z index variables
$zindex-content: 1037 !default;
$zindex-sidenav: 1038 !default;
$zindex-topnav: 1039 !default;

// Color variables for the sidenav

// -- Sidenav Dark
// #0063B1
$sidenav-dark-bg: $theme-color-primary;
$sidenav-dark-color: $white;
$sidenav-dark-heading-color: $white;
$sidenav-dark-link-color: lighten($theme-color-primary, 60);
$sidenav-dark-link-active-color: lighten($theme-color-primary, 60);
$sidenav-dark-icon-color: lighten($theme-color-primary, 60);
$sidenav-dark-footer-bg: $theme-color-dark;

// -- Sidenav Light
$sidenav-light-bg: darken($theme-color-secondary, 30);
$sidenav-light-color: lighten($theme-color-secondary, 50);
$sidenav-light-heading-color: lighten($theme-color-secondary, 20);
$sidenav-light-link-color: $sidenav-light-color;
$sidenav-light-link-active-color: lighten($theme-color-primary, 20);
$sidenav-light-icon-color: lighten($theme-color-secondary, 20);
$sidenav-light-footer-bg: lighten($theme-color-secondary, 20);

// Color variables for the topnav
$topnav-dark-toggler-color: $white;
$topnav-light-toggler-color: $white;
