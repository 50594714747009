/*
 * Bootstrap
 */
// najskor importujem kustomizovane premenne, ktore potom prepisu defaultne premenne
@import "../theme/css/mixins-new";
@import '../theme/css/bootstrap-custom-variables';
@import '../theme/css/sidebar-variables';
@import "~bootstrap";
@import "../theme/css/mixins-override";

/*
 * DATATABLES
 */
// ak by nieco nefungovalo, tak je mozne naimportovat aj kompletne css so stiahnuteho balicka.
//@import "../downloaded/DataTables/datatables.css";

@import "~datatables.net-bs4";
@import "~datatables.net-buttons-bs4";
@import "~datatables.net-responsive-bs4";
@import "~datatables.net-searchpanes-bs4";
@import "~datatables.net-select-bs4";
@import "../theme/css/datatables-override";

/*
 * Select2
 */
@import "~select2";
@import "../theme/css/select2-override";

/*
 * Tempus dominus - Datepicker
 */
@import "../theme/downloaded/TempusDominus/tempusdominus-bootstrap-4.min.css";

// formularove prvky
@import "../theme/css/form-overrides";

// Sidebar menu
@import "../theme/css/sidebar";

// horne menu
@import "../theme/css/top-navbar";

// nadpisy
@import "../theme/css/headings";

// tlacidla
@import "../theme/css/buttons";

// tabs
@import "../theme/css/tabs";

/*
 * Ikony
 */
@import "../theme/css/material-icons";
// font awesome kvoli kalendaru tempus-dominus, kym neprideme na to ako zmenit ikony.
@import "~font-awesome";

/*
 * Calendar
 */
@import "../theme/css/calendar";

/*
 * Slider
 */
@import '../theme/css/slider';
/*
 * Vseobecne upravy
 */
@import "../theme/css/general";

@import "../theme/downloaded/passtrenght/passtrenght";

@import "../theme/downloaded/sb-admin/dist/css/styles.css";

/*
 * CKEditor
 */
@import "../theme/css/ckeditor";