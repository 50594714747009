/*
 * select2
 */
// Skrytie originalneho selectu aby neprekryval select2
select.select2, select.custom-select{
  border:none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none; /* get rid of default appearance for IE8, 9 and 10*/
}

// vyska
.select2-container .select2-selection--single, .select2-container--default .select2-selection--single .select2-selection__rendered, .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 39.5px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 39.5px;
}

// zaoblene rohy
.select2-container--default .select2-selection--multiple {
  border-radius: 0;
}

.select2-container--default .select2-selection--single {
  border-radius: 0;
}

// vybrane polozky pre multiselect
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: $theme-color-info;
  color: $white;
  border: 0;
  border-radius: 0;
  padding: 1px 5px 5px 5px;
  font-size: 0.9em;
}

// krizik pri vybranej polozke
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: $white;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #ccc;
}

.select2 {
  width: 100%;
  display: block;
}

input.select2-search__field:focus {
  border-color: $form-focus-color !important;
  box-shadow: $form-focus-shadow;
}

// custom select
.custom-select {
  box-shadow: none;
}