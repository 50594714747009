html, body {
  height: 100%;
}
.no-shadow {
  box-shadow: none;
}

.no-select {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.login-box{
  margin-top: 19%;
}

.form-group{
  background-color: white !important;
}
legend{
  background-color: white !important;
}