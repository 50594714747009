// tieto styly sluia hlavne na formatovanie input grupy a zobrazovacieho pola pre aktualnu hodnotu
.custom-range {
  width: 100%;
  height: 2.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
  border: none;
  box-shadow: none;
  transition: none;
}

.custom-range:focus {
  border: none !important;;
  box-shadow: none !important;
}

.custom-range + div > span {
  background-color: white !important;
  border: none !important;
  color: $theme-color-secondary;
  width: 50px;
}

